<template>
  <div class="price-lists">

    <b-row class="m-b-sm">
      <b-col>
        <div class="ibox-content no-borders b-r-lg">

          <!-- title -->
          <div>
            <div class="article m">
              <h1>{{ $t("prologistics.name") }} | {{ $t("multiples.price_list.other") }}</h1>
            </div>
          </div>

          <!-- table -->
          <div>
            <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"/>
          </div>

        </div>
      </b-col>
    </b-row>

    <default-modal v-model="recordModalShow" :id="recordId" :title="recordModalTitle"
                   :type="recordModalType" :inputs="modalInputs" :fetch-record="fetchRecord"
                   :disabled="recordModalType==='show'"
                   :submitRecord="submitRecord"
    />
  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import {
  getPriceList,
  getPriceLists, getSelectableRoles,
  postPriceList,
} from "@/services/price_lists";
import {mapGetters} from "vuex";
import DefaultModal from "@/components/default_modal";

export default {
  components: {DefaultModal, Datatable},
  data() {
    return {
      recordId: 0,
      recordModalShow: false,
      recordModalType: '',
      recordModalTitle: '',
      tab: {
        columns: [
          {key: "id", label: "id"},
          {
            key: "lteq_30",
            name: "30Km",
            formatter: (value, key, item) => this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          },
          {
            key: "lteq_100",
            name: "100Km",
            formatter: (value, key, item) => this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          },
          {
            key: "lteq_200",
            name: "200Km",
            formatter: (value, key, item) => this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          },
          {
            key: "lteq_300",
            name: "300Km",
            formatter: (value, key, item) => this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          },
          {
            key: "km",
            name: "pro Km",
            formatter: (value, key, item) => this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          },
          {key: "role_description", name: this.$t('common.role')},
          {key: "actions", label: "actions"},
        ],
        fetchFunction: async (filterQuery) => {
          const response = await getPriceLists(filterQuery)
          this.tab.recordsTotal = response.recordsTotal || 0
          response.data.forEach((entry) => {
            entry.actions = [
              {
                label: this.$t('button.show'),
                action: this.showRecordDetailModal
              }
            ]
          })
          return response
        },
        actions: [
          {
            label: this.$t('price_lists.add'),
            action: this.showCreateModal
          },
        ],
        recordsTotal: -1
      },
      modalInputs: [
        {key: "lteq_30", label: "30Km", type: 'currency', required: true},
        {key: "lteq_40", label: "40Km", type: 'currency', required: true},
        {key: "lteq_50", label: "50Km", type: 'currency', required: true},
        {key: "lteq_60", label: "60Km", type: 'currency', required: true},
        {key: "lteq_70", label: "70Km", type: 'currency', required: true},
        {key: "lteq_80", label: "80Km", type: 'currency', required: true},
        {key: "lteq_90", label: "90Km", type: 'currency', required: true},
        {key: "lteq_100", label: "100Km", type: 'currency', required: true},
        {key: "lteq_150", label: "150Km", type: 'currency', required: true},
        {key: "lteq_200", label: "200Km", type: 'currency', required: true},
        {key: "lteq_250", label: "250Km", type: 'currency', required: true},
        {key: "lteq_300", label: "300Km", type: 'currency', required: true},
        {key: "km", label: "pro Km", type: 'currency', required: true},
        {
          key: "role",
          label: this.$t('common.role'),
          type: 'select',
          fetchRecords: () => getSelectableRoles({}),
          required: true
        }
      ]
    }
  },
  methods: {
    fetchRecord(id) {
      return getPriceList(id)
    },
    showCreateModal() {
      this.recordId = 0
      this.recordModalType = 'create'
      this.recordModalTitle = this.$t('price_lists.add')
      this.recordModalShow = true
    },
    showRecordDetailModal(entry) {
      this.recordId = entry.id
      this.recordModalTitle = this.$t('price_lists.show')
      this.recordModalType = 'show'
      this.recordModalShow = true
    },
    submitRecord(entry) {
      return postPriceList(entry)
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  }
}
</script>